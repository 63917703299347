import useGoogleSheets from 'use-google-sheets';
import './Results.css';

export default function Results(props) {
    const { data, loading, error } = useGoogleSheets({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID,
        sheetsNames: ['Partnerliste!A:B'],
    });
    if (props.showResults){
        if (data){
            if (loading) { return <p>loading...</p> }
            if (error) { return <p>error...</p> }
            if (data && data[0].data) { 
                const entries = data[0].data.filter(function (el){
                    return el.PARTNER.toLowerCase().trim().includes(props.input.toLowerCase().trim())
                })
                return (<>{
                    entries.map((row, index) => {
                        return (
                            <div className="row" key={row.PARTNER+index}>
                                { row.LOGO &&
                                <img src={row.LOGO} className="row-image" alt="logo" width="132" height="62"/>
                                }
                                <p className="text">
                                    {row.PARTNER}
                                </p>
                            </div>
                        )
                    })
                    }</>)
            }
        }
    }else{return null}

}
